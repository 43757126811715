import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';

import App from './routes';

import GlobalStyle from 'theme/GlobalStyles';
import store from 'redux/store';
import { theme } from 'theme/theme';

export const history = createBrowserHistory();
const params = new URLSearchParams(window.location.search);
if (params.get("action") === "set-token") {
  window.addEventListener('message', (message) => {
    if (message.data.token) {
      localStorage.setItem('token', message.data.token);
    }
  });
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App history={history} />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );

  if (module.hot) {
    module.hot.accept();
  }
}
