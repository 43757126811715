import React from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';

import Home from 'pages/Home';
import News from 'pages/News';
import YouthPolitics from 'pages/YouthPolitics';
import Table from 'pages/Table';
import AuthGames from 'pages/AuthGames';
import Audit from 'pages/Audit';
import Collect from 'pages/Collect';

import Pusher from 'services/pusher';
import { AUTH_API, faces } from 'settings';
import {
  authSelector,
  getInfo,
  setInfoBalance,
  setPollSum,
  loginStatus,
  getInfoSuccess,
  сhangeAuthInfoJackpots,
} from 'redux/authReducer';
import { useSelector, useDispatch } from 'react-redux';
import MainPreloader from 'components/Common/MainPreloader';
import { showOffer } from 'components/AuthGames/AuthPageContainer';
import { DEBUG } from 'settings';

axios.defaults.baseURL = AUTH_API;

let isNewToken = false;

const App = () => {
  const dispatch = useDispatch();

  const JWT = localStorage.getItem('token');
  if (localStorage.getItem('is_new_token')) {
    isNewToken = true;
    localStorage.removeItem('is_new_token');
  }

  React.useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${JWT}`;
  }, [JWT]);

  React.useEffect(() => {
    !status && !JWT && dispatch(loginStatus(401));
  }, []);

  React.useEffect(() => {
    axios.interceptors.response.use((response) => {
      if (response?.data?.code === 401) {
        navigate('/');
        dispatch(loginStatus(401));
        dispatch(getInfoSuccess(null));
        localStorage.removeItem('token');
      }
      return response;
    });
  }, []);

  const {
    authInfo: { info, loading },
    templateId,
    status,
  } = useSelector(authSelector);

  const [pusherClient, setPusherClient] = React.useState(null);

  React.useEffect(() => {
    if (!!info?.pusher && !pusherClient) {
      setPusherClient(
        new Pusher(info.pusher.key, {
          cluster: info.pusher.cluster,
          forceTLS: true,
          authEndpoint: `${AUTH_API}/auth/pusher`,
          auth: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              Accept: 'application/json',
            },
          },
        })
      );
    }
  }, [info?.pusher, pusherClient]);

  React.useEffect(() => {
    !!JWT && !info && dispatch(getInfo());
  }, [status]);

  const navigate = useNavigate();

  const typeConvert = (type) => {
    switch (type) {
      case 0:
        return 'bronze';
      case 1:
        return 'silver';
      case 2:
        return 'gold';
      case 3:
        return 'diamond';
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (!!pusherClient) {
      const channel = pusherClient.subscribe(
        `private-player-${info && info.id}`
      );
      channel.bind('change-player', (data) => {
        console.log(window.disablePusher, data)
        if (window.disablePusher) {
          return;
        }
        if (data.cmd !== undefined && data.cmd === 'reload') {
          window.location.reload();
        }
        data.pollscore !== undefined && dispatch(setPollSum(data.pollscore));
        data.balance !== undefined && dispatch(setInfoBalance(data.balance));
        "add_balance" in data && data.add_balance > 0 && info.is_social_poll && showOffer();
        if ("status" in data) {
          localStorage.removeItem('token');
          dispatch(loginStatus(401));
          dispatch(getInfoSuccess(null));
          navigate('/');
        }
      });
      channel.bind('action-player', (data) => {
        data[0] === 'collect' && navigate('/audit');
        data[0] === 'change-jackpot' &&
          dispatch(
            сhangeAuthInfoJackpots({
              type: typeConvert(data[1]),
              jackpot: data[2],
            })
          );
        // data[0] === 'collect' && navigate('/audit');
      });
      return () =>
        pusherClient &&
        function () {
          channel.unbind('change-player');
          channel.unbind('action-player');
        };
    }
    // eslint-disable-next-line
  }, [dispatch, pusherClient]);

  const privateRender = (route) => {
    if (!loading) {
      if (status === 200) {
        return <Navigate to="/authgames" />;
      } else {
        return route;
      }
    }
  };

  const authGamesRender = (route) => {
    if (!loading && !!status) {
      if (status === 200) {
        return route;
      } else {
        return <Navigate to="/" />;
      }
    }
  };

  let redirectUrl = null;
  React.useEffect(() => {
    if (templateId !== 0 && faces[templateId]) {
      if (DEBUG) {
        console.log(`redirect to ${faces[templateId]}`);
      } else {
        if (isNewToken) {
          redirectUrl = faces[templateId];
        } else {
          window.location.href = faces[templateId];
        }
      }
    }
  }, [templateId, JWT]);

  return <>
    {isNewToken && faces.filter(url => url).map((url, idx) => <iframe key={idx} style={{ display: 'none' }} src={`${url}?action=set-token`} onLoad={(e) => { e.target.contentWindow.postMessage({ token: JWT }, '*'); if (redirectUrl === url) { setTimeout(() => window.location.href = redirectUrl, 300) } }} />)}
    {loading ? <MainPreloader /> : <Routes>
      <Route exact path="/" element={privateRender(<Home pusherClient={pusherClient} />)} />
      <Route path="/news" element={privateRender(<News />)} />
      <Route path="/youthpolitics" element={privateRender(<YouthPolitics />)} />
      <Route path="/table" element={privateRender(<Table />)} />
      <Route path="/authgames" element={authGamesRender(<AuthGames pusherClient={pusherClient} />)} />
      <Route path="/audit" element={<Audit />} />
      <Route path="/collect" element={<Collect />} />
    </Routes>}
  </>;
};

export default App;
